import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from '../../../common/components/runtime-context';
import ActionButton from '../../components/action-button';
import withTranslate from '../../../common/components/with-translate/with-translate';
import { isMemberAreaInstalled } from '../../../common/store/communities-context/communities-context-selectors';
import withAuth from '../../hoc/with-auth';

export class PrivateProfileActions extends Component {
  handleGoToProfile = () => {
    const { buttonClicked, navigateToProfile, user } = this.props;
    buttonClicked({ action: 'profile' });
    navigateToProfile({ memberId: user.siteMemberId, memberSlug: user.slug });
  };

  handleLogout = () => {
    const { buttonClicked, navigateToRoot, logoutMember } = this.props;
    navigateToRoot();
    buttonClicked({ action: 'logout' });
    logoutMember();
  };

  handleGoToSettings = () => {
    const { buttonClicked, navigateToProfileSettings, user } = this.props;
    buttonClicked({ action: 'settings' });
    navigateToProfileSettings(user);
  };

  render() {
    const { t, isMaInstalled } = this.props;
    return (
      <div>
        {isMaInstalled && (
          <>
            <ActionButton
              data-hook="private-profile-actions__profile"
              onClick={this.handleGoToProfile}
            >
              {t('breadcrumbs.profile')}
            </ActionButton>
            <ActionButton
              data-hook="private-profile-actions__settings"
              onClick={this.handleGoToSettings}
            >
              {t('private-profile-actions.settings')}
            </ActionButton>
          </>
        )}
        <ActionButton data-hook="private-profile-actions__logout" onClick={this.handleLogout}>
          {t('private-profile-actions.logout')}
        </ActionButton>
      </div>
    );
  }
}

PrivateProfileActions.propTypes = {
  buttonClicked: PropTypes.func,
  logoutMember: PropTypes.func,
  navigateToRoot: PropTypes.func,
  t: PropTypes.func,
  user: PropTypes.object,
  isMaInstalled: PropTypes.bool,
};

const mapRuntimeToProps = (state, ownProps, actions) => ({
  buttonClicked: (data) =>
    actions.buttonClicked({ name: 'action_click', type: 'profile', ...data }),
  logoutMember: actions.logout,
  navigateToRoot: actions.navigateToRoot,
  navigateToProfile: actions.navigateToProfile,
  navigateToProfileSettings: actions.navigateToProfileSettings,
  isMaInstalled: isMemberAreaInstalled(state),
});

// prettier-ignore
export default flowRight(
  connect(mapRuntimeToProps),
  withAuth,
  withTranslate
)(PrivateProfileActions);
