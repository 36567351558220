import classNames from 'classnames';
import styles from '../search-input-suggestions.scss';
import { Spinner, TextButton, SpinnerTypes, TextButtonPriority } from 'wix-ui-tpa';
import SearchIcon from 'wix-ui-icons-common/on-stage/Search';
import React from 'react';
import { SearchInputSuggestionsState } from '../search-input-suggestions.slice';
import { TFunction } from '@wix/yoshi-flow-editor';
import { MAX_RESULTS_COUNT } from '../constants/max-results-count';
import SearchResultItem from '../search-result-item/search-result-item.component';

type SearchResultsStateSwitchProps = {
  state: SearchInputSuggestionsState;
  t: TFunction;
  onMoreResultsClick: () => void;
  onSearchItemClick: () => void;
};

export const SearchResultsStateSwitch: React.FC<SearchResultsStateSwitchProps> = ({
  state,
  t,
  onMoreResultsClick,
  onSearchItemClick,
}) => {
  return (
    <>
      {state.type === 'OPEN' &&
        (() => {
          switch (state.dropdownState?.type) {
            case 'LOADING': {
              return (
                <div className={classNames(styles.dropdown, styles.emptyState)}>
                  <Spinner type={SpinnerTypes.slim} diameter={26} />
                  {t('search-input.querying')}
                </div>
              );
            }
            case 'EMPTY': {
              return (
                <div className={classNames(styles.dropdown, styles.emptyState)}>
                  {t('search-input.no-results')}
                </div>
              );
            }
            case 'READY': {
              const data = state.dropdownState.data;
              return (
                <div
                  data-hook="search-suggestions-dropdown"
                  className={classNames(styles.dropdown)}
                >
                  <h4 className={styles.dropdownSection}>
                    {t('search-input.results.posts-and-comments')}
                  </h4>
                  <ul>
                    {data.postsAndComments.map((entity) => (
                      <SearchResultItem
                        entity={entity}
                        query={data.query}
                        onClick={onSearchItemClick}
                      />
                    ))}
                    {data.metadata.totalPostsAndComments > MAX_RESULTS_COUNT && (
                      <li data-hook="search-suggestions-dropdown-see-all">
                        <TextButton
                          onClick={onMoreResultsClick}
                          className={classNames(styles.dropdownMoreLink)}
                          prefixIcon={<SearchIcon />}
                          priority={TextButtonPriority.primary}
                        >
                          {t('search-input.results.see-all-results')}
                        </TextButton>
                      </li>
                    )}
                  </ul>
                </div>
              );
            }
            default: {
              return <></>;
            }
          }
        })()}
    </>
  );
};
