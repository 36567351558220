import {
  URI as uri,
  buildPaginationRequestParams,
  getSortQueryParams,
  getHeader,
} from '@wix/communities-forum-client-commons';
import createAction from '../../common/services/create-action';
import { getCategoryPageSorting } from '../selectors/sorting-selectors';
import { getPostTypeFilter } from '../selectors/filter-selectors';
import { fetchCounters } from './fetch-counters';
import { getIsMainPageEnabled, getStyle } from '../selectors/app-settings-selectors';
import { FeedType, getActiveFeedName, MainPageType } from '../containers/header-navigation';

const mapBestAnswerToMarkedComments = (posts) => {
  return (
    posts &&
    posts.map((post) => ({
      ...post,
      ...(post.bestAnswerCommentId ? { markedComments: [post.bestAnswerCommentId] } : {}),
    }))
  );
};

export const FETCH_UPDATED_POSTS_SUCCESS = 'posts/FETCH_UPDATED_POSTS_SUCCESS';

export const fetchUpdatedPostsSuccess = createAction(
  FETCH_UPDATED_POSTS_SUCCESS,
  // @TODO remove mapBestAnswerToMarkedComments, when markedComents is implemented in back-end
  (payload) => mapBestAnswerToMarkedComments(payload),
  (payload, meta) => meta,
);

export default function fetchUpdatedPosts({
  siteMemberId,
  categoryId,
  page,
  pageSize,
  sort,
  postType,
}) {
  return (dispatch, getState, { request }) => {
    const state = getState();
    const sorting = sort || getCategoryPageSorting(state, getStyle(state), categoryId);
    const sortingConfig = getSortQueryParams(sorting);
    const postTypeFilter = postType || getPostTypeFilter(state);
    const params = buildPaginationRequestParams(page, pageSize);

    const isMainPageCategoriesList = getIsMainPageEnabled(state, state);
    const mainPageType = isMainPageCategoriesList ? MainPageType.Categories : MainPageType.Posts;
    const queryFeedType = getActiveFeedName(state, mainPageType);
    const ignorePinnedSort =
      queryFeedType === FeedType.MyPosts ||
      (mainPageType === MainPageType.Categories && queryFeedType === FeedType.Posts);

    const promise = request(
      uri('/posts').query({
        ...(categoryId ? { categoryId } : {}),
        ...(siteMemberId ? { siteMemberId } : {}),
        ...params,
        ...sortingConfig,
        postType: postTypeFilter,
        ...(ignorePinnedSort ? { ignorePinnedSort: true } : {}),
      }),
      { parseHeaders: true },
    );

    return promise
      .then(({ body = [], headers = {} }) => {
        dispatch(fetchCounters({ postIds: body.map((p) => p._id) }));
        dispatch(
          fetchUpdatedPostsSuccess(body, {
            sort: sorting,
            categoryId,
            siteMemberId,
            page,
            entityCount: parseInt(getHeader(headers, 'Wix-Forum-TotalResults'), 10),
          }),
        );
      })
      .then(() => promise);
  };
}
