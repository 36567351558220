import {
  CARD_SIZE_LARGE,
  CARD_SIZE_MEDIUM,
  CARD_SIZE_SMALL,
} from '@wix/communities-forum-client-commons';

export enum CardSizes {
  SMALL = CARD_SIZE_SMALL,
  MEDIUM = CARD_SIZE_MEDIUM,
  LARGE = CARD_SIZE_LARGE,
}
