import PropTypes from 'prop-types';
import React, { FC } from 'react';
import BreadcrumbsWidget from '../breadcrumbs-widget';
import DesktopHeader from '../desktop-header';
import HeaderTabs from '../header-navigation/header-navigation.component';
import { connect } from '../../../common/components/runtime-context';
import { flowRight } from 'lodash';
import { RootState } from '../../types/store-types';
import styles from './internal-page-tabs-header.scss';
import SearchInputSuggestions from '../../components/search-input-suggestions';
import { encodeQuery } from '../../services/query-encoding';
import { SearchTriggerLocation } from '../../components/search-input-suggestions/constants/search-trigger-location';
import { SearchSuggestionsApiResult } from '../../../api/search/models';

interface InternalPageTabsDesktopHeaderProps {
  params: any;
  routes: any;
  customCtaLabel: string;
  onSearch: (query: string) => void;
  onSearchFocus: () => void;
  onSearchClear: () => void;
  onSearchSuggestionsLoaded: (query: string) => void;
  searchQuery: (query: string) => void;
  fetchSearchSuggestions: (query: string, limit: number) => Promise<SearchSuggestionsApiResult>;
  userEventsSearchSubmitted: (payload: {
    query: string;
    location: SearchTriggerLocation;
  }) => Promise<any>;
}

const InternalPageTabsDesktopHeader: FC<InternalPageTabsDesktopHeaderProps> = ({
  params,
  routes,
  onSearch,
  onSearchFocus,
  onSearchClear,
  onSearchSuggestionsLoaded,
  customCtaLabel,
  fetchSearchSuggestions,
  userEventsSearchSubmitted,
  ...props
}) => (
  <>
    <DesktopHeader
      className={styles.header}
      removeVerticalSpacing={true}
      removeStartSpacing={true}
      left={<HeaderTabs />}
      right={
        <BreadcrumbsWidget
          searchInput={
            <SearchInputSuggestions
              onSearch={(query: string, location: SearchTriggerLocation) => {
                onSearch(query);
                userEventsSearchSubmitted({ query, location });
              }}
              initialQuery={params.query || ''}
              onFocus={onSearchFocus}
              onClear={onSearchClear}
              onSuggestionsLoaded={onSearchSuggestionsLoaded}
              fetchSearchSuggestions={(query: string, limit: number) =>
                fetchSearchSuggestions(query, limit)
              }
            />
          }
          params={params}
        />
      }
      {...props}
    />
  </>
);

InternalPageTabsDesktopHeader.propTypes = {
  params: PropTypes.object.isRequired,
};

const mapRuntimeToProps = (state: RootState, _: any, actions: any) => ({
  onSearch: (query: string) =>
    actions.navigateWithinForum(`/search${query ? `/${encodeQuery(query)}` : ''}`),
  onSearchFocus: actions.userEventsSearchFocused,
  onSearchClear: actions.userEventsSearchCleared,
  onSearchSuggestionsLoaded: actions.userEventsSearchSuggestionsLoaded,
  fetchSearchSuggestions: actions.fetchSearchSuggestionsPromisified,
  userEventsSearchSubmitted: actions.userEventsSearchSubmitted,
});

export default flowRight(connect(mapRuntimeToProps))(InternalPageTabsDesktopHeader);
