import React from 'react';
import Header from '../../components/header';
import HeaderButtons from '../../components/header-buttons';
import PropTypes from 'prop-types';
import HeaderTabs from '../header-navigation/header-navigation.component';

const CategoriesPageHeader = ({ params }) => (
  <Header left={<HeaderTabs />} right={<HeaderButtons params={params} />} />
);

CategoriesPageHeader.propTypes = {
  params: PropTypes.object.isRequired,
};

export default CategoriesPageHeader;
