import {
  VIEW_MODE_DASHBOARD,
  VIEW_MODE_EDITOR,
  VIEW_MODE_PREVIEW,
  VIEW_MODE_SITE,
} from '../../constants/view-modes';
import { getCurrentUser } from '../current-user/current-user-selectors';

export const getBasicParams = (state) => state.basicParams || {};

export const getViewMode = (state) => getBasicParams(state).viewMode;
export const getFormFactor = (state) => getBasicParams(state).formFactor;
export const getInstance = (state) => getBasicParams(state).instance;
export const getLanguage = (state) => getBasicParams(state).language || 'en';
export const isSSR = (state) => getBasicParams(state).isSSR;
export const getApiBaseUrl = (state) => getBasicParams(state).apiBaseUrl;
export const isDebug = (state) => getBasicParams(state).isDebug;
export const isProduction = (state) => getBasicParams(state).isProduction;
export const getRicosBiParams = (state) => getBasicParams(state).ricosBiParams;

export const isSite = (state) => getViewMode(state) === VIEW_MODE_SITE;
export const isEditor = (state) => getViewMode(state) === VIEW_MODE_EDITOR;
export const isPreview = (state) => getViewMode(state) === VIEW_MODE_PREVIEW;
export const isDashboard = (state) => getViewMode(state) === VIEW_MODE_DASHBOARD;
export const isSeo = (state) => getBasicParams(state).isSeo;
export const getIsHomePage = (state) => getBasicParams(state).isHomePage;
export const getIsMobile = (state) => getFormFactor(state) === 'Mobile';
export const getIsForumWidthLessThan = (state, width) =>
  state.host.dimensions.width ? state.host.dimensions.width < width : 980 < width;
export const getForumWidth = (state) => state.host.dimensions?.width || 980;
export const getIsForumLoadedOnInitialPage = (state) => {
  const pageNumber = getBasicParams(state).pageNumber;
  return pageNumber !== undefined && pageNumber === 1;
};

export const isCacheableRendering = (state) => isSSR(state) && !getCurrentUser(state);
