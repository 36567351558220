import { get } from 'lodash';
import { RCE_FAILED_UPLOAD_ID } from '../constants/upload';

const isFailedImageUpload = (entity) =>
  entity.type === 'wix-draft-plugin-image' && get(entity, 'data.src.id') === RCE_FAILED_UPLOAD_ID;

const isFailedVideoUpload = (entity) =>
  entity.type === 'wix-draft-plugin-video' &&
  get(entity, 'data.src.pathname') === RCE_FAILED_UPLOAD_ID;

const isFailedFileUpload = (entity) =>
  entity.type === 'wix-draft-plugin-file-upload' && get(entity, 'data.id') === RCE_FAILED_UPLOAD_ID;

const removeFailedUploads = (content) => {
  Object.keys(content.entityMap).forEach((key) => {
    const entity = content.entityMap[key];
    const shouldRemoveEntity =
      isFailedImageUpload(entity) || isFailedVideoUpload(entity) || isFailedFileUpload(entity);

    shouldRemoveEntity && delete content.entityMap[key];
  });

  const blockKeysToRemove = content.blocks.filter((block) => {
    const entityKey = get(block, 'entityRanges[0].key');
    return content.entityMap[entityKey];
  });

  content.blocks = content.blocks.filter((block) => !blockKeysToRemove.includes(block.key));

  return content;
};

export default removeFailedUploads;
